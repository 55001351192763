import {
    FETCH_RATE,
    FETCH_RATE_SUCCESS,
    FETCH_RATE_FAILED,
    EDIT_RATE
  } from "../store/types";
  import store from '../store/store';
  import { firebase } from '../config/configureFirebase';
  import { onValue, update, set, child, remove, push } from "firebase/database";
  import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
  
  export const fetchRate= () => (dispatch) => {
  
    const {
        rateRef
    } = firebase;
  
    dispatch({
      type: FETCH_RATE,
      payload: null
    });

    onValue(rateRef, async snapshot => {
      
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_RATE_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_RATE_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_rate
        });
      }
    });
  };
  
  export const editRate = (rate, method) => async (dispatch) => {
  const {
    rateRef, 
    rateEditRef
  } = firebase;
  dispatch({
    type: EDIT_RATE,
    payload: { method, rate }
  });
  if (method === 'Add') {
    push(rateRef, rate);
  } else if (method === 'Delete') {
    remove(rateEditRef(rate.id));
  } else {
    set(rateEditRef(rate.id), rate);
  }
}

