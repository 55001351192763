import { container, title } from "./material-kit-react.js"
import { MAIN_COLOR, SECONDORY_COLOR, FONT_FAMILY } from "../common/sharedFunctions";
import { colors } from "components/Theme/WebTheme.js"; 

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    fontFamily:FONT_FAMILY,
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#383838",
    textDecoration: "none"
  },
  description:{
    color: "#383838",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  rtlDescription:{
    textAlign:"right",
    color: "#383838",
    marginRight:"30px",
    wordBreak: "break-word"
  },
  ltrDescription:{
    textAlign:"left",
    color: "#383838",
    marginRight:"20px",
    wordBreak: "break-word"
  }, 
container1: {
    backgroundColor: colors.Header_Text,
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    padding: '30px',
    width: '100%',
    top: "19px",
    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
},
title: {
    color: MAIN_COLOR,
    padding: '10px',
    fontSize: 18,
    fontFamily: FONT_FAMILY
},
items: {
    margin: 0,
    width: '100%'
},
input: {
    fontSize: 18,
    color: colors.BLACK,
    fontFamily: FONT_FAMILY
},
inputdimmed: {
    fontSize: 18,
    color: colors.CARD_LABEL
},
carphoto: {
    height: '18px',
    marginRight: '10px'
},
farecardstyle: {
    backgroundColor: MAIN_COLOR,
    borderRadius: "20px",
    paddingBottom: "15px"
},
buttonStyle: {
    margin: 0,
    width: '100%',
    height: 40,
    borderRadius: "30px",
    backgroundColor: MAIN_COLOR,
    marginTop: '15px',
    color: colors.WHITE,
    fontWeight: "bold",
    fontFamily: FONT_FAMILY
},
label: {
    transformOrigin: "top right",
    right: 0,
    left: "auto"
},
inputRtl: {
    "& label": {
        right: 20,
        left: "auto",
        fontFamily: FONT_FAMILY
    },
    "& legend": {
        textAlign: "right",
    },
    "& label.Mui-focused": {
        color: MAIN_COLOR,
        fontFamily: FONT_FAMILY
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: MAIN_COLOR,
        },
    },

    fontFamily: FONT_FAMILY
},
textField: {
    "& label": {
        fontFamily: FONT_FAMILY
    },
    "& label.Mui-focused": {
        color: MAIN_COLOR,
        fontFamily: FONT_FAMILY
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: MAIN_COLOR,
        },
    },
    fontFamily: FONT_FAMILY
},
selectField: {
    color: colors.BLACK,
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: MAIN_COLOR,
    },
},
};

export default landingPageStyle;
