export const FirebaseConfig = {
	"projectId": "my-taxi-c3869",
	"appId": "1:628191565571:web:d4185d78e8c06069e03b36",
	"databaseURL": "https://my-taxi-c3869-default-rtdb.firebaseio.com",
	"storageBucket": "my-taxi-c3869.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBqDLR2AORpOqHYJVD4kFsITDJzhkQmxG0",
	"authDomain": "taxi2fly.net",
	"messagingSenderId": "628191565571",
	"measurementId": "G-HHJ2Y5QMPT"
};
