import {
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { api } from 'common';
import Button from "components/CustomButtons/Button.js";
import moment from "moment/min/moment-with-locales";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { FONT_FAMILY, MAIN_COLOR, SECONDORY_COLOR } from '../common/sharedFunctions';
import { colors } from '../components/Theme/WebTheme';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
    fontFamily: FONT_FAMILY
  },
  typography: {
    fontFamily: FONT_FAMILY,
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 480,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${colors.BLACK}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    marginTop: theme.spacing(1),
    backgroundColor: MAIN_COLOR,
    alignContent: 'center',
    borderRadius: "8px",
    width: '70%',

  },
  container1: {
    backgroundColor: colors.LandingPage_Background,
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    padding: '30px',
    width: '100%',
    top: "19px",
    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
  },
  title: {
    color: colors.WHITE,
    padding: '10px',
    backgroundColor: MAIN_COLOR,
    fontFamily: FONT_FAMILY,
    borderRadius: "10px",
    fontSize: 18,

  },
  gridcontainer: {
    alignContent: 'center'
  },
  items: {
    margin: 0,
    width: '100%'
  },
  input: {
    fontSize: 18,
    color: colors.BLACK,
    fontFamily: FONT_FAMILY,
  },
  inputdimmed: {
    fontSize: 18,
    color: colors.CARD_LABEL,
    fontFamily: FONT_FAMILY,
  },
  carphoto: {
    height: '18px',
    marginRight: '10px'
  },
  carphotoRtl: {
    height: '16px',
    marginLeft: '10px'
  },
  buttonStyle: {
    margin: 0,
    width: '100%',
    height: 40,
    borderRadius: "30px",
    backgroundColor: MAIN_COLOR,
    color: colors.WHITE,
    fontFamily: FONT_FAMILY,
  },
  buttonStyle1: {
    backgroundColor: MAIN_COLOR,
    fontFamily: FONT_FAMILY
  },
  buttonStyle2: {
    backgroundColor: SECONDORY_COLOR,
    fontFamily: FONT_FAMILY
  },
  inputRtl: {
    "& label": {
      right: 25,
      left: "auto",
      fontFamily: FONT_FAMILY,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 18,
      fontFamily: FONT_FAMILY,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
    "& input": {
      fontFamily: FONT_FAMILY,
    },
  },
  rightRty: {
    "& legend": {
      marginRight: 30,
      fontFamily: FONT_FAMILY,
    },
    "& input": {
      fontFamily: FONT_FAMILY,
    },
  },
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
    "& input": {
      fontFamily: FONT_FAMILY,
    },
  },
}));
export default function ManageBookingDetails() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    updateBooking1
  } = api;
  const dispatch = useDispatch();

  const rootRef = useRef(null);
  const classes = useStyles();
  const cartypes = useSelector(state => state.cartypes.cars);
  const ManageBooking = useSelector(state => state.bookinglistdata);
  const [data, setData] = useState(null);
  const [userCombo, setUserCombo] = useState('');
  const [pickupAddress, setPickupAddress] = useState('');
  const [dropAddress, setDropAddress] = useState('');
  const [carType, setCarType] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const navigate = useNavigate();
  const [selectedCarDetails, setSelectedCarDetails] = useState(null);
  const [instructionData, setInstructionData] = useState({
    otherPerson: "",
    otherPersonPhone: "",
    pickUpInstructions: "",
    deliveryInstructions: "",
    parcelTypeIndex: 0,
    optionIndex: 0,
    parcelTypeSelected: null,
    optionSelected: null
  });

  useEffect(() => {
    if (ManageBooking.bookings) {
      const booking = ManageBooking.bookings.find(
        (item) => item.id === id.toString()
      );
      if (booking) {
        console.log('booking', booking);
        setData(booking);
        setUserCombo(`${booking.customer_name} (${booking.customer_contact}) ${booking.customer_email}`);
        setPickupAddress(booking.pickupAddress);
        setDropAddress(booking.dropAddress);
        setCarType(booking.carType);
        //   const date = new Date(booking.tripdate);
        //   const formattedDate = date.toISOString().slice(0, 16); // "yyyy-MM-ddThh:mm" format
        //   setSelectedDate(formattedDate);
        setSelectedDate(moment(booking.tripdate).format('YYYY-MM-DDTHH:mm'));
      } else {
        navigate("/404");
        setData(null);
      }
    }
    console.log("managedata", data);
  }, [ManageBooking.bookings, id, navigate]);

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!data) return;

    console.log(selectedDate)
    const updatedBooking = {
      ...data,
      tripdate: new Date(selectedDate).getTime()
    };

    console.log("updatedBooking", updatedBooking);
    try {
      dispatch(updateBooking1(updatedBooking));
      console.log('Booking updated successfully');
      navigate("/Managebooking");
    } catch (error) {
      console.error('Error updating booking:', error);
    }
  };
  const handleCarSelect = (event) => {
    setCarType(event.target.value);
    let carDetails = null;
    let carspecial_fare = null;
    console.log("cartypes", cartypes);
    for (let i = 0; i < cartypes.length; i++) {
      if (cartypes[i].name === event.target.value) {
        carDetails = cartypes[i];
        let instObj = { ...instructionData };
        if (Array.isArray(cartypes[i].parcelTypes)) {
          instObj.parcelTypeSelected = cartypes[i].parcelTypes[0];
          instObj.parcelTypeIndex = 0;
        }
        if (Array.isArray(cartypes[i].options)) {
          instObj.optionSelected = cartypes[i].options[0];
          instObj.optionIndex = 0;
        }
        setInstructionData(instObj);
      }
      if (cartypes[i]?.special_fare) {
        carspecial_fare = cartypes[i]?.special_fare;
      }
    }
    setSelectedCarDetails(carDetails);
    console.log("carDetails", carDetails);
    console.log("special_fare", carspecial_fare);
  };
  return (
    <div>
      <div
        dir={isRTL ? "rtl" : "ltr"}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button
          variant="text"
          onClick={() => navigate("/Managebooking")}
          style={{ background: '#f4f6f9', boxShadow: 'none', padding: 0 }}
        >
          <Typography
            style={{
              margin: "10px 10px 0 5px",
              textAlign: isRTL ? "right" : "left",
              fontWeight: "bold",
              color: MAIN_COLOR,
              fontFamily: FONT_FAMILY,
            }}
          >
            {`${t("go_back")} ->>`}
          </Typography>
        </Button>
      </div>

      <div className={classes.container} ref={rootRef}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid item xs={12}>
            <Typography className={classes.title} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontFamily: FONT_FAMILY }}>
              {t('Manage Bookings')}
            </Typography>
          </Grid>
          <div className={classes.container1}>
            {data && (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label>{t('User')}</label>
                    <TextField
                      type="text"
                      value={userCombo}
                      className={classes.items}
                      onChange={(e) => setUserCombo(e.target.value)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>{t('pickup_address')}</label>
                    <TextField
                      type="text"
                      value={pickupAddress}
                      className={classes.items}
                      onChange={(e) => setPickupAddress(e.target.value)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>{t('drop_address')}</label>
                    <TextField
                      type="text"
                      value={dropAddress}
                      className={classes.items}
                      onChange={(e) => setDropAddress(e.target.value)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="text"
                      value={carType}
                      onChange={(e) => setCarType(e.target.value)}
                      disabled
                    />
                    {/* {cartypes ?
                    <Select
                       labelId="demo-simple-select-label"
                       id="demo-simple-select"
                        value={carType}
                        onChange={handleCarSelect}
                        variant="outlined"
                        fullWidth
                        style={{textAlign:isRTL==='rtl'? 'right':'left',}}
                        className={carType === t('select_car') ? classes.inputdimmed : classes.input}
                        sx={{color: colors.BLACK,
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: MAIN_COLOR,
                        },}}
                       
                    >
                      <MenuItem dense={true} value={t('select_car')} key={t('select_car')} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10,fontFamily:FONT_FAMILY}}>
                        {t('select_car')}
                      </MenuItem>
                      {
                        cartypes.sort((a, b) => a.pos - b.pos).map((car) =>
                          <MenuItem dense={true}  key={car.name} value={car.name} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10,fontFamily:FONT_FAMILY}}>
                            <img src={car.image} className={isRTL==='rtl'? classes.carphotoRtl : classes.carphoto} alt="car types"/>{car.name}
                          </MenuItem>
                        )
                      }
                    </Select>
                  : null} */}
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      type="text"
                      value="Book Now"
                      onChange={(e) => setCarType(e.target.value)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
                      id="datetime-local"
                      label={t('booking_date_time')}
                      type="datetime-local"
                      variant="outlined"
                      fullWidth
                      className={[isRTL === 'rtl' ? classes.inputRtl : classes.commonInputStyle, classes.textField].join(" ")}
                      InputProps={{
                        className: classes.input,
                        style: { textAlignLast: isRTL === 'rtl' ? 'end' : 'start' }
                      }}
                      value={selectedDate}
                      onChange={onDateChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      size="lg"
                      variant="contained"
                      color="secondaryButton"
                      type="submit"
                      className={classes.buttonStyle}
                    >
                      <i className="fas fa-car" style={isRTL === 'rtl' ? { marginLeft: 5 } : { marginRight: 5 }} />
                      {t('Update Booking')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </div>
        </Grid>
      </div>
    </div>
  );
}