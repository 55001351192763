import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AlertDialog from "../components/AlertDialog";
import CircularLoading from "components/CircularLoading";
import { colors } from "components/Theme/WebTheme";
import { MAIN_COLOR, SECONDORY_COLOR, FONT_FAMILY } from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";
import { api } from "common";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: FONT_FAMILY,
  },
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
    "& input": {
      fontFamily: FONT_FAMILY,
    },
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
  },
  rootRtl: {
    "& label": {
      right: 20,
      left: "auto",
      paddingRight: 20,
      fontFamily: FONT_FAMILY,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 15,
      fontFamily: FONT_FAMILY,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
    "& input": {
      fontFamily: FONT_FAMILY,
    },
  },
}));

const EditFare = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const settings = useSelector((state) => state.settingsdata.settings);
  const fareList = useSelector((state) => state.farelist);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const { editFare } = api;
  const [data, setData] = useState([
    {
      pincode1: "",
      pincode2: "",
      fare: 0,
    },
  ]);
  const classes = useStyles();
  const isRTL = false;

  useEffect(() => {
    if (id && fareList && fareList.fare) {
      const fareData = fareList.fare.find((item) => item.id === id.toString());
      if (!fareData) {
        navigate("/404");
      } else {
        setData([fareData]);
      }
    }
  }, [fareList, id, navigate]);

  const handleInputChange = (index, e) => {
    const { id, value } = e.target;
    setData((prevData) => {
      const newData = [...prevData];
      newData[index][id] = value;
      return newData;
    });
  };

  const handleSelectChange = (index, e) => {
    const { value } = e.target;
    setData((prevData) => {
      const newData = [...prevData];
      newData[index].pincode2 = value;
      return newData;
    });
  };

  const addFareField = () => {
    setData((prevData) => [
      ...prevData,
      { pincode1: "", pincode2: "", fare: 0 },
    ]);
  };

  const removeFareField = (index) => {
    setData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    let invalidEntry = false;
    data.forEach((entry) => {
      if (!entry.pincode1 || !entry.pincode2 || !entry.fare) {
        setCommonAlert({ open: true, msg: "All fields are required for each fare entry." });
        invalidEntry = true;
      }
    });
    if (invalidEntry) return;

    const action = id ? "Update" : "Add";

    if (settings.AllowCriticalEditsAdmin) {
      setLoading(true);
      new Promise((resolve, reject) => {
        data.forEach((entry, index) => {
          setTimeout(() => {
            if (action === "Add") {
              entry.createdAt = new Date().getTime();
              dispatch(editFare(entry, action));
            } else {
              const existingFare = fareList.fare.find((item) => item.id === id.toString());
              if (entry === existingFare) {
                dispatch(editFare(entry, action));
              } else {
                setCommonAlert({ open: true, msg: t("make_changes_to_update") });
                reject(new Error("No changes to update"));
              }
            }
            if (index === data.length - 1) {
              setLoading(false);
              navigate("/p2p");
              resolve();
            }
          }, 600 * (index + 1));
        });
      }).catch((error) => {
        console.error(error);
        setLoading(false);
      });
    } else {
      setTimeout(() => {
        setCommonAlert({ open: true, msg: t("demo_mode") });
      }, 600);
    }
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  return loading ? (
    <CircularLoading />
  ) : (
    <div>
      <Card
        style={{
          borderRadius: "19px",
          backgroundColor: colors.WHITE,
          minHeight: 100,
          maxWidth: "100vw",
          marginTop: 20,
          marginBottom: 20,
          padding: 25,
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
        }}
      >
        <Typography
          variant="h5"
          style={{
            margin: "10px 10px 0 5px",
            textAlign: isRTL ? "right" : "left",
            fontFamily: FONT_FAMILY,
          }}
        >
          {id ? "Edit Fare" : t("Add Fare")}
        </Typography>
        <div
          dir={isRTL ? "rtl" : "ltr"}
          style={{
            marginBottom: 20,
          }}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate("/p2p");
            }}
          >
            <Typography
              style={{
                margin: "10px 10px 0 5px",
                textAlign: isRTL ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
                fontFamily: FONT_FAMILY,
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div>

        {data.map((fare, index) => (
          <Grid container spacing={2}  style={{marginBottom:"20px"}} sx={{ direction: isRTL ? "rtl" : "ltr" }} key={index}>
            
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              {data.length > 1 && (
                 <IconButton 
                 style={{
                   borderRadius: "19px", 
                   minHeight: 50,
                   minWidth: "5%",
                   textAlign: "center",
                   fontFamily: FONT_FAMILY,
                 }}
                 onClick={() => removeFareField(index)}
                 color="secondary"
               >
        <DeleteOutlinedIcon  style={{color:'black'}}/> 
               </IconButton>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <TextField
                InputLabelProps={{ style: { fontFamily: "Arial" } }}
                label="Pincode"
                id="pincode1"
                value={fare.pincode1}
                fullWidth
                onChange={(e) => handleInputChange(index, e)}
                className={isRTL ? classes.rootRtl : classes.textField}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}> 
              <TextField
                InputLabelProps={{ style: { fontFamily: "Arial" } }}
                label="Pincode"
                id="pincode2"
                value={fare.pincode2}
                fullWidth
                onChange={(e) => handleInputChange(index, e)}
                className={isRTL ? classes.rootRtl : classes.textField}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <TextField
                InputLabelProps={{ style: { fontFamily: "Arial" } }}
                label="Fare"
                id="fare"
                value={fare.fare}
                fullWidth
                type="number"
                onChange={(e) => handleInputChange(index, e)}
                className={isRTL ? classes.rootRtl : classes.textField}
              />
            </Grid>

          </Grid>
        ))}

{!id && (
        <Grid container spacing={2} sx={{ direction: isRTL ? "rtl" : "ltr", marginTop: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button
              style={{
                borderRadius: "19px",
                backgroundColor: "green",
                minHeight: 50,
                minWidth: "50%",
                textAlign: "center",
                fontFamily: FONT_FAMILY,
              }}
              onClick={addFareField}
              variant="contained"
              color="primary"
            >
              {t("Add Another Fare")}
            </Button>
          </Grid>
        </Grid>
        )}

        <Grid container spacing={2} sx={{ direction: isRTL ? "rtl" : "ltr", marginTop: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button
              style={{
                borderRadius: "19px",
                backgroundColor: "blue",
                minHeight: 50,
                minWidth: "50%",
                textAlign: "center",
                fontFamily: FONT_FAMILY,
              }}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
            >
              {id ? t("update") : t("Add Fare")}
            </Button>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default EditFare;
